<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('callcenter.timesheet_daily_wizard.second_step_title') }}</p>
      </div>
    </div>
    <ValidationObserver ref="hourForm">
      <div class="row">
        <div class="col-12 text-center mb-3">
          <h4>{{ date | date }}</h4>
        </div>

        <div class="col-12 col-md-6" v-for="(operator, index) in operatorsLocal" v-bind:key="`operators-${index}`">

          <div class="d-flex align-items-center mb-0">
            <div class="flex-grow-1 text-truncate">
              {{ operator.user | optional('lastname') }}
              {{ operator.user | optional('firstname') }}
            </div>

            <ValidationProvider
              name="hour"
              :vid="`hour-${index}`"
              rules="numeric|between:0,24"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                class="ml-3 mb-0 hour-input"
                maxlength="2"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                v-model="operator.hours"
              />
            </ValidationProvider>
          </div>
          <base-input
            class="flex-grow-1 mb-4"
            :label="$t('fields.notes')"
            :placeholder="$t('fields.notes')"
            v-model="operator.notes[day]"
          />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>

export default {
  name: "TimesheetDailyWizardSecondStep",
  data() {
    return {
      operatorsLocal: [],
    }
  },
  props: {
    operators: {
      type: Array,
      default: () => []
    },
    date: {
      type: String,
      default: ''
    },
  },
  mounted() {
    if (this.operators.length > 0) {
      this.operatorsLocal = this.$_.map(this.$_.cloneDeep(this.operators), operator => {
        return {
          user: operator.user,
          hours: operator.hours,
          notes: !this.$_.isEmpty(operator.notes) ? operator.notes : {},
        }
      });
    }
  },
  computed: {
    day() {
      return 'day_' + this.$moment(this.date).format('DD');
    }
  },
  methods: {

    getOperators() {

      return new Promise((resolve, reject) => {
        this.$refs.hourForm.validate()
          .then((success) => {
            if (success) {
              resolve(this.operatorsLocal);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  }
}
</script>

<style scoped>
.hour-input{
  width: 80px;
}
</style>
