<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('callcenter.timesheet_daily_wizard.first_step_title') }}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-4">
        <ValidationObserver ref="dateForm">
          <ValidationProvider
            name="date"
            rules="required"
            vid="confirmation"
            v-slot="{ passed, failed, errors }"
          >
            <base-input :label="$t('common.select_day')"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
              <el-date-picker
                type="date"
                v-model="date"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('common.select_day')"
                :picker-options="{firstDayOfWeek: 1}"
              />
            </base-input>
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {DatePicker} from "element-ui"

  export default {
    name: "TimesheetDailyWizardFirstStep",
    components: {[DatePicker.name]: DatePicker},
    data() {
      return {
        date: this.$moment().format('YYYY-MM-DD'),
      }
    },
    methods: {
      validate() {
        return new Promise((resolve, reject) => {
          this.$refs.dateForm.validate()
            .then((success) => {
              if (!success) {
                reject();
              } else {
                resolve(this.date);
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    }
  }
</script>

<style scoped>

</style>
