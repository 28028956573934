<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('callcenter.timesheet_daily_wizard.third_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mb-3">
        <h4>{{ date | date }}</h4>
      </div>
      <div class="col-12 col-md-6"
           v-for="(operator, index) in operators"
           v-bind:key="`operators-${index}`">
        <el-card class="m-1" shadow="hover" :body-style="{ padding: '10px' }">
          <div class="d-flex justify-content-between">
            <div>
              {{ operator.user | optional('lastname') }}
              {{ operator.user | optional('firstname') }}
            </div>
            <div>
              <label-theme-component>
                {{ operator.hours ? operator.hours + ' ' + $t('fields.hours') : 'N.D.'  }}
              </label-theme-component>
            </div>
          </div>
        </el-card>

      </div>
    </div>
  </div>
</template>

<script>
  import {Card} from "element-ui";
  import LabelThemeComponent from "@/components/LabelThemeComponent";

  export default {
    name: "TimesheetDailyWizardThirdStep",
    components: {
      LabelThemeComponent,
      [Card.name]: Card,
    },
    props: {
      operators: {
        type: Array,
        default: () => []
      },
      date: {
        type: String,
        default: ''
      },
    }
  }
</script>

<style scoped>

</style>
