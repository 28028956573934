<template>
  <div class="row">
    <div class="col-md-12">
      <simple-wizard
        :key="`wizard-${wizardKey}`"
        @tab-change="tabChange"
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('callcenter.timesheet_daily_wizard.title') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="annual-calendar-page"></octo-icon>
            <p>{{ $t('callcenter.timesheet_daily_wizard.date_choice') }}</p>
          </template>
          <timesheet-daily-wizard-first-step ref="timesheetDailyWizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="clock"></octo-icon>
            <p>{{ $t('callcenter.timesheet_daily_wizard.insert_hours') }}</p>
          </template>
          <timesheet-daily-wizard-second-step ref="timesheetDailyWizardSecondStep"
                                              :operators="operators"
                                              :date="date"
                                              :key="`second-step-${secondStepKey}`"
          />
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="checked"></octo-icon>
            <p>{{ $t('callcenter.timesheet_daily_wizard.report') }}</p>
          </template>
          <timesheet-daily-wizard-third-step :operators="operators"
                                             :date="date"
                                             :key="`third-step-${thirdStepKey}`"/>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import TimesheetDailyWizardFirstStep from "@/pages/CallCenter/timesheet-daily-wizard/TimesheetDailyWizardFirstStep";
import TimesheetDailyWizardSecondStep from "@/pages/CallCenter/timesheet-daily-wizard/TimesheetDailyWizardSecondStep";
import TimesheetDailyWizardThirdStep from "@/pages/CallCenter/timesheet-daily-wizard/TimesheetDailyWizardThirdStep";
import {MessageBox} from "element-ui";

export default {
  name: "TimesheetDailyWizardPage",
  components: {
    TimesheetDailyWizardThirdStep,
    TimesheetDailyWizardSecondStep,
    TimesheetDailyWizardFirstStep,
    OctoIcon,
    WizardTab,
    SimpleWizard,
  },
  data() {
    return {
      date: this.$moment().format('YYYY-MM-DD'),
      operators: [],
      secondStepKey: 0,
      thirdStepKey: 0,
      wizardKey: 0
    }
  },
  computed: {
    day: function () {
      return this.date.slice(8);
    },
    month: function () {
      return this.date.slice(5, 7);
    },
    year: function () {
      return this.date.slice(0, 4);
    },
    requestBody() {
      return {
        date: this.date,
        users: this.$_.map(this.operators, operator => {
          return {
            id: operator.user.id,
            hours: operator.hours,
            notes: operator.notes,
          }
        })
      }
    }
  },
  methods: {
    async validateStep(step) {
      let check = false;
      switch (step) {
        case 1:
          const data = await this.$refs.timesheetDailyWizardFirstStep.validate();
          if (data) {
            check = true;
            if (data !== this.date) {
              this.date = data;
              this.operators = [];
            }
          }
          break;
        case 2:
          const operators = await this.$refs.timesheetDailyWizardSecondStep.getOperators();
          if (operators) {
            this.operators = operators;
            check = true;
          }
          break;
        case 3:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    tabChange(oldTab, newTab) {
      if (newTab.tabId === '1' && this.operators.length === 0) {
        this.$fullLoading.show();
        this.$api.get(
          endpoints.CALL_CENTER_TIMESHEET_OPERATOR_DAILY_INDEX
            .replace('{year}', this.year)
            .replace('{month}', this.month)
            .replace('{day}', this.day)
        )
          .then((resp) => {
            this.$fullLoading.hide();
            this.operators = resp.data;
            this.secondStepKey++;
          })
          .catch(() => {
            this.$fullLoading.hide();
            this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
          });
      }

      if (newTab.tabId === '2') {
        this.thirdStepKey++;
      }
    },
    completeWizard() {
      this.$api.post(endpoints.CALL_CENTER_TIMESHEET_OPERATOR_DAILY_STORE, this.requestBody)
        .then(() => {
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('callcenter.timesheet_daily_wizard.back_to_beginning'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.operators = [];
              this.wizardKey++;
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
